<template>
    <b-col lg="12" class="mt-3">
        <b-card title="Orders">
            <b-card-text>
                <b-table striped hover
                         :busy="isBusy"
                         :items="orders"
                         :fields="fields"
                         :current-page="currentPage"
                         :per-page="perPage"
                >
                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </b-table>
                <b-row>
                    <b-col md="2" class="my-1">
                        <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                class="my-0"
                        ></b-pagination>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </b-col>
</template>

<script>
    export default {
        name: "OrdersList",
        props: {
            orders: Array,
        },
        data() {
            return {
                fields: ['name', 'financial_status', 'total_price', 'created_at'],
                currentPage: 1,
                perPage: 5,
                isBusy: false,
            }
        },
        methods: {
            toggleBusy() {
                this.isBusy = !this.isBusy;
                console.log(this.isBusy);
            }
        },
        computed: {
            totalRows() { return this.orders.length }
        }
    }
</script>

<style scoped>

</style>