var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "customer-modal",
          attrs: {
            scrollable: "",
            title: "Update customer",
            id: "modal-xl",
            size: "xl"
          }
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { lg: "6" } },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { prepend: "First name" } },
                        [
                          _c("b-form-input", {
                            attrs: { id: "first_name" },
                            model: {
                              value: _vm.customer.first_name,
                              callback: function($$v) {
                                _vm.$set(_vm.customer, "first_name", $$v)
                              },
                              expression: "customer.first_name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { lg: "6" } },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { prepend: "Last name" } },
                        [
                          _c("b-form-input", {
                            attrs: { id: "last_name" },
                            model: {
                              value: _vm.customer.last_name,
                              callback: function($$v) {
                                _vm.$set(_vm.customer, "last_name", $$v)
                              },
                              expression: "customer.last_name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { lg: "3" } },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { prepend: "Phone #" } },
                        [
                          _c("b-form-input", {
                            attrs: { id: "phone_number" },
                            model: {
                              value: _vm.customer.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.customer, "phone", $$v)
                              },
                              expression: "customer.phone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { lg: "3" } },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { prepend: "Email" } },
                        [
                          _c("b-form-input", {
                            attrs: { id: "email" },
                            model: {
                              value: _vm.customer.email,
                              callback: function($$v) {
                                _vm.$set(_vm.customer, "email", $$v)
                              },
                              expression: "customer.email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { lg: "6" } },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { prepend: "Tags" } },
                        [
                          _c("b-form-input", {
                            attrs: { id: "tags" },
                            model: {
                              value: _vm.customer.tags,
                              callback: function($$v) {
                                _vm.$set(_vm.customer, "tags", $$v)
                              },
                              expression: "customer.tags"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.customer.addresses
                    ? _c("addresses-list", {
                        attrs: { addresses: _vm.customer.addresses }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.customer.orders
                    ? _c("orders-list", {
                        attrs: { orders: _vm.customer.orders }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-3",
                              attrs: { variant: "outline-danger", block: "" },
                              on: { click: _vm.hideModal }
                            },
                            [_vm._v("Close")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-3",
                              attrs: { variant: "outline-warning", block: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.updateCustomer(_vm.customer.id)
                                }
                              }
                            },
                            [_vm._v("Update")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }