<template>
    <div>
        <b-modal ref="order-modal" scrollable title="Update order" id="modal-xl" size="xl">
            <b-container>
                <b-row>
                    <b-col lg="6" class="mt-3" v-if="order.shipping_address">
                        <b-input-group prepend="Customer name">
                            <b-form-input id="name" v-model="order.shipping_address.name"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" class="mt-3" v-if="order.shipping_address">
                        <b-input-group prepend="Phone #">
                            <b-form-input id="name" v-model="order.shipping_address.phone"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" class="mt-3" v-if="order.shipping_address">
                        <b-input-group prepend="Email">
                            <b-form-input id="name" v-model="order.contact_email"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" class="mt-3" v-if="order.shipping_address">
                        <b-input-group prepend="Currency">
                            <b-form-input id="name" v-model="order.currency"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col lg="12" class="mt-3">
                        <b-card title="Products">
                            <b-card-text>
                                <b-table striped hover
                                         :items="order.line_items"
                                         :fields="fields"
                                >
                                </b-table>
                                <vue-bootstrap-typeahead
                                        v-model="query"
                                        :data="products"
                                        :serializer="item => item.title"
                                        prepend="Search product:"
                                        placeholder="Add product to the order"
                                >
                                    <!-- Begin custom suggestion slot -->
                                    <template slot="suggestion" slot-scope="{ data, htmlText }">
                                        <div class="d-flex align-items-center">
                                            <img
                                                    class="rounded-circle"
                                                    :src="data.image_id"
                                                    style="width: 40px; height: 40px;" />

                                            <!-- Note: the v-html binding is used, as htmlText contains
                                                 the suggestion text highlighted with <strong> tags -->
                                            <span class="ml-4" v-html="htmlText"></span>
                                        </div>
                                    </template>
                                </vue-bootstrap-typeahead>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>

            </b-container>
            <div slot="modal-footer" class="w-100">
                <b-container>
                    <b-row>
                        <b-col lg="6">
                            <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close</b-button>
                        </b-col>
                        <b-col lg="6">
                            <b-button class="mt-3" variant="outline-warning" block @click.prevent="updateOrder(order.dtRowId) ">Update</b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';

    export default {
        name: "OrderModal",
        components: {
            VueBootstrapTypeahead
        },
        data() {
            return {
                fields: ['name', 'sku', 'quantity', 'price', 'total_discount', 'price'],
                currentPage: 1,
                perPage: 5,
                isBusy: false,
                order: {},
                shipping_address: {},
                query: '',
                products: []
            }
        },
        created() {
            eventBus.$on('showOrderModal', (item) => this.showOrderModal(item))
        },
        methods: {
            showOrderModal(item) {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.$refs['order-modal'].toggle('#name');
                this.order = item;
                this.order.shipping_address = JSON.parse(item.shipping_address);
                this.order.line_items = JSON.parse(item.line_items);

            },
            hideModal() {
                this.$refs['order-modal'].hide()
            },
            updateOrder(item)
            {
                axios.put('/orders/'+item, this.order)
                    .then(res => {
                        this.order = res.data
                    }).catch(err => {
                    console.log(err)
                })
            },
            searchProduct(newQuery) {
                axios.get(`/variants/search?query=${newQuery}`)
                    .then((res) => {
                        console.log(res.data)
                        this.products = res.data
                    })
            },
        },
        watch: {
            // When the query value changes, fetch new results from
            // the API - debounce the search to avoid hitting the API limits
            query: _.debounce(function(newQuery) { this.searchProduct(newQuery) }, 250)
        },
    }
</script>

<style scoped>

</style>