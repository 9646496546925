var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { staticClass: "mt-3", attrs: { lg: "12" } },
    [
      _c(
        "b-card",
        { attrs: { title: "Addresses" } },
        [
          _c(
            "b-card-text",
            [
              _c(
                "b-table",
                {
                  attrs: {
                    striped: "",
                    hover: "",
                    busy: _vm.isBusy,
                    items: _vm.addresses,
                    fields: _vm.fields,
                    "current-page": _vm.currentPage,
                    "per-page": _vm.perPage
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-center text-danger my-2",
                      attrs: { slot: "table-busy" },
                      slot: "table-busy"
                    },
                    [
                      _c("b-spinner", { staticClass: "align-middle" }),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Loading...")])
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "my-1", attrs: { md: "2" } },
                    [
                      _c("b-pagination", {
                        staticClass: "my-0",
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }