var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "order-modal",
          attrs: {
            scrollable: "",
            title: "Update order",
            id: "modal-xl",
            size: "xl"
          }
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _vm.order.shipping_address
                    ? _c(
                        "b-col",
                        { staticClass: "mt-3", attrs: { lg: "6" } },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { prepend: "Customer name" } },
                            [
                              _c("b-form-input", {
                                attrs: { id: "name" },
                                model: {
                                  value: _vm.order.shipping_address.name,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.order.shipping_address,
                                      "name",
                                      $$v
                                    )
                                  },
                                  expression: "order.shipping_address.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _vm.order.shipping_address
                    ? _c(
                        "b-col",
                        { staticClass: "mt-3", attrs: { lg: "6" } },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { prepend: "Phone #" } },
                            [
                              _c("b-form-input", {
                                attrs: { id: "name" },
                                model: {
                                  value: _vm.order.shipping_address.phone,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.order.shipping_address,
                                      "phone",
                                      $$v
                                    )
                                  },
                                  expression: "order.shipping_address.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _vm.order.shipping_address
                    ? _c(
                        "b-col",
                        { staticClass: "mt-3", attrs: { lg: "6" } },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { prepend: "Email" } },
                            [
                              _c("b-form-input", {
                                attrs: { id: "name" },
                                model: {
                                  value: _vm.order.contact_email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.order, "contact_email", $$v)
                                  },
                                  expression: "order.contact_email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _vm.order.shipping_address
                    ? _c(
                        "b-col",
                        { staticClass: "mt-3", attrs: { lg: "6" } },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { prepend: "Currency" } },
                            [
                              _c("b-form-input", {
                                attrs: { id: "name" },
                                model: {
                                  value: _vm.order.currency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.order, "currency", $$v)
                                  },
                                  expression: "order.currency"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { lg: "12" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { title: "Products" } },
                        [
                          _c(
                            "b-card-text",
                            [
                              _c("b-table", {
                                attrs: {
                                  striped: "",
                                  hover: "",
                                  items: _vm.order.line_items,
                                  fields: _vm.fields
                                }
                              }),
                              _vm._v(" "),
                              _c("vue-bootstrap-typeahead", {
                                attrs: {
                                  data: _vm.products,
                                  serializer: function(item) {
                                    return item.title
                                  },
                                  prepend: "Search product:",
                                  placeholder: "Add product to the order"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "suggestion",
                                    fn: function(ref) {
                                      var data = ref.data
                                      var htmlText = ref.htmlText
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center"
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "rounded-circle",
                                              staticStyle: {
                                                width: "40px",
                                                height: "40px"
                                              },
                                              attrs: { src: data.image_id }
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "ml-4",
                                              domProps: {
                                                innerHTML: _vm._s(htmlText)
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.query,
                                  callback: function($$v) {
                                    _vm.query = $$v
                                  },
                                  expression: "query"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-3",
                              attrs: { variant: "outline-danger", block: "" },
                              on: { click: _vm.hideModal }
                            },
                            [_vm._v("Close")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-3",
                              attrs: { variant: "outline-warning", block: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.updateOrder(_vm.order.dtRowId)
                                }
                              }
                            },
                            [_vm._v("Update")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }