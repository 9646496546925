<template>
    <div style="width: 100%">
        <div class="column is-one-fifth">

            <customer-modal ref="customer-modal"></customer-modal>
            <order-modal ref="order-modal"></order-modal>

            <select-filter multiple
                               source="/api/select/stores"
                               label="name"
                               title="Filter by store"
                               v-model="filters.stores.id"/>
        </div>

    <vue-table class="box"
               :filters="filters"
               path="/api/table/orders/initTable"
               id="orders">

        <span slot="shipping_name"
              slot-scope="{ row, column }">
            <a @click.prevent="showCustomerModal(row.customer_id)" href="#">{{ row.first_name }} {{ row.last_name }}</a>
        </span>

        <template v-slot:preview="{ row }">
            {{ row }}
        </template>

        <span slot="name"
              slot-scope="{ row, column }">
            <a @click.prevent="showOrderModal(row)" href="#">{{ row.name }}</a>
        </span>
    </vue-table>
    </div>
</template>

<script>
    import { VueTable } from '@enso-ui/tables/bulma';
    import { SelectFilter } from '@enso-ui/filters/bulma';
    import CustomerModal from "./CustomerModal";
    import OrderModal from "./OrderModal";

    export default {
        components: {
            CustomerModal,
            OrderModal,
            VueTable,
            SelectFilter
        },
        data: () => ({
            filters: {
                stores: {
                    id: [],
                },
            },
        }),
        methods: {
            showCustomerModal(item) { eventBus.$emit('showCustomerModal', item) },
            showOrderModal(item) { eventBus.$emit('showOrderModal', item) }
        }
    }
</script>