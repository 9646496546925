<template>
    <div style="width: 100%">
        <div class="column is-one-fifth">
            <select-filter multiple
                           source="/api/select/stores"
                           label="name"
                           title="Filter by store"
                           v-model="filters.stores.id"/>
        </div>
        <vue-table class="box"
                   :filters="filters"
                   path="/api/table/variants/initTable"
                   id="variants">

        <span slot="image"
              slot-scope="{ row, column }">
            <img :src="row.image_id" width="50px" height="50px"/>
        </span>
        </vue-table>
    </div>
</template>

<script>
    import { VueTable } from '@enso-ui/tables/bulma';
    import { SelectFilter } from '@enso-ui/filters/bulma';

    export default {
        components: {
            VueTable,
            SelectFilter
        },
        data: () => ({
            filters: {
                stores: {
                    id: [],
                },
            },
        })
    }
</script>