var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "div",
        { staticClass: "column is-one-fifth" },
        [
          _c("select-filter", {
            attrs: {
              multiple: "",
              source: "/api/select/stores",
              label: "name",
              title: "Filter by store"
            },
            model: {
              value: _vm.filters.stores.id,
              callback: function($$v) {
                _vm.$set(_vm.filters.stores, "id", $$v)
              },
              expression: "filters.stores.id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("vue-table", {
        staticClass: "box",
        attrs: {
          filters: _vm.filters,
          path: "/api/table/variants/initTable",
          id: "variants"
        },
        scopedSlots: _vm._u([
          {
            key: "image",
            fn: function(ref) {
              var row = ref.row
              var column = ref.column
              return _c("span", {}, [
                _c("img", {
                  attrs: { src: row.image_id, width: "50px", height: "50px" }
                })
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }