<template>
    <div>
        <b-modal ref="customer-modal" scrollable title="Update customer" id="modal-xl" size="xl">
            <b-container>
                <b-row>
                    <b-col lg="6" class="mt-3">
                        <b-input-group prepend="First name">
                            <b-form-input id="first_name" v-model="customer.first_name"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col lg="6" class="mt-3">
                        <b-input-group prepend="Last name">
                            <b-form-input id="last_name" v-model="customer.last_name"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col lg="3" class="mt-3">
                        <b-input-group prepend="Phone #">
                            <b-form-input id="phone_number" v-model="customer.phone"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col lg="3" class="mt-3">
                        <b-input-group prepend="Email">
                            <b-form-input id="email" v-model="customer.email"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col lg="6" class="mt-3">
                        <b-input-group prepend="Tags">
                            <b-form-input id="tags" v-model="customer.tags"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <addresses-list :addresses="customer.addresses" v-if="customer.addresses"></addresses-list>
                    <orders-list :orders="customer.orders" v-if="customer.orders"></orders-list>
                </b-row>
            </b-container>

            <div slot="modal-footer" class="w-100">
                <b-container>
                    <b-row>
                        <b-col lg="6">
                            <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close</b-button>
                        </b-col>
                        <b-col lg="6">
                            <b-button class="mt-3" variant="outline-warning" block @click.prevent="updateCustomer(customer.id) ">Update</b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import OrdersList from "./OrdersList";
    import AddressesList from "./AddressesList";
    export default {
        components: {AddressesList, OrdersList},
        data() {
            return {
                customer: {},
            }
        },
        created() {
          eventBus.$on('showCustomerModal', (item) => this.showModal(item))
        },
        methods: {
            showModal(item) {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.$refs['customer-modal'].toggle('#first_name');
                this.getCustomer(item);
            },
            hideModal() {
                this.$refs['customer-modal'].hide()
            },
            getCustomer(item)
            {
                axios.get('/customers/'+item)
                    .then(res => {
                        this.customer = res.data;
                    }).catch(err => {
                    console.log(err)
                });
            },
            updateCustomer(item)
            {
                axios.put('/customers/'+item, this.customer)
                    .then(res => {
                        this.customer = res.data
                    }).catch(err => {
                    console.log(err)
                })
            }
        }
    }
</script>