<template>
    <div style="width: 100%">
        <div class="column is-one-fifth">
            <customer-modal ref="customer-modal"></customer-modal>
            <select-filter multiple
                           source="/api/select/stores"
                           label="name"
                           title="Filter by store"
                           v-model="filters.stores.id"/>
        </div>
        <vue-table class="box"
                   :filters="filters"
                   path="/api/table/customers/initTable"
                   @details="handleDetails"
                   id="customers">

            <span slot="full_name"
                  slot-scope="{ row, column }">
                <a href="#" @click.prevent="showModal(row.dtRowId)" >{{ row.full_name }}</a>
            </span>
        </vue-table>
    </div>
</template>

<script>
    import { VueTable } from '@enso-ui/tables/bulma';
    import { SelectFilter } from '@enso-ui/filters/bulma';
    import CustomerModal from "./CustomerModal";

    export default {
        components: {
            CustomerModal,
            VueTable,
            SelectFilter
        },
        data: () => ({
            filters: {
                stores: {
                    id: [],
                },
            },
        }),
        methods: {
            handleDetails(event) {
                const id = event.dtRowId;
                alert(id);
            },
            showModal(item) { eventBus.$emit('showCustomerModal', item) }
        },
    }
</script>