<template>
    <b-col lg="12" class="mt-3">
        <b-card title="Addresses">
            <b-card-text>
                <b-table striped hover
                         :busy="isBusy"
                         :items="addresses"
                         :fields="fields"
                         :current-page="currentPage"
                         :per-page="perPage"
                >
                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </b-table>
                <b-row>
                    <b-col md="2" class="my-1">
                        <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                class="my-0"
                        ></b-pagination>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </b-col>
</template>

<script>
    export default {
        name: "AddressesList",
        props: {
            addresses: Array,
        },
        data() {
            return {
                fields: ['company', 'address1', 'city', 'state', 'country_name', 'zip'],
                currentPage: 1,
                perPage: 5,
                isBusy: false,
            }
        },
        methods: {
            toggleBusy() {
                this.isBusy = !this.isBusy;
                console.log(this.isBusy);
            }
        },
        computed: {
            totalRows() { return this.addresses.length }
        }
    }
</script>